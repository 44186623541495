import React, { useState, useEffect } from "react"
import images from '../../themes/appImage'
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { loginAction } from '../../redux/Action/Auth'
import { useDispatch } from 'react-redux'

const Login = () => {
    const [eye, setEye] = useState(false)
    const dispatch = useDispatch()
    const formik = useFormik({
        initialValues: {
            email: "",
            password: ""
        },
        validationSchema: Yup.object().shape({
            email: Yup.string()
                .required("Please enter email address.")
                .matches(
                    /^[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+(\.[a-zA-z]{2,10})+$/,
                    "Please enter valid email address."
                )
                .max(255),
            password: Yup.string()
                .required("Please enter password.")
                .matches(/^(\S+$)/, "Please enter valid password."),
        }),

        onSubmit: (values) => {
            dispatch(loginAction(values))
        }
    })


    useEffect(() => {
        document.title = 'Albastini';
    }, [])

    return (
        <div className="image-bg">
            <div className="page-header">


                <div className="main-div">

                    <div className="login-form">
                        <div> <img alt="" src={images.login} /></div>
                        <h3>Admin Login </h3>
                        <form noValidate onSubmit={formik.handleSubmit}>
                            <div className="user">
                                <div>
                                    <input
                                        type="text"
                                        placeholder="Email Address"
                                        name="email"
                                        onChange={(e) => {
                                            let data = e.target.value.trim();
                                            formik.setFieldValue("email", data);
                                        }}
                                        // onInput={(e) => {
                                        //     console.log('input entered', e.target.value)
                                        //     if ((e.target.value).startsWith('')) e.preventDefault()
                                        // }}
                                        value={formik.values.email.trim()}

                                    />
                                    {formik.errors.email && formik.touched.password ? (
                                        <div className="error">{formik.errors.email}</div>
                                    ) : null}
                                </div>
                                <div>
                                    <input
                                        style={{ paddingRight: "40px" }}
                                        type={!eye ? "password" : "text"}
                                        placeholder="Password"
                                        name="password"
                                        onChange={(e) => {
                                            let data = e.target.value.trim();
                                            formik.setFieldValue("password", data);
                                        }}
                                        value={formik.values.password}
                                    />
                                    <img alt="" className="eyemask" onClick={() => setEye((prev) => !prev)} src={!eye ? images.hidden : images.eye}></img>
                                    {formik.errors.password && formik.touched.password ? (
                                        <div className="error">{formik.errors.password}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="login-link">
                                <button className="login-link" type='submit' >Login</button>
                            </div>
                        </form>
                        <div className="forgot-link">
                            <Link to="/forgotpassword" >Forgot Password?</Link>
                        </div>
                    </div>



                </div>
            </div>
        </div>

    )
}
export default Login;
