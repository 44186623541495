import React, { useEffect } from "react"
import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome'
import { faHome, } from "@fortawesome/free-solid-svg-icons";
import { Breadcrumb } from 'antd';
import images from "../../themes/appImage";
import { dashboard } from '../../redux/Action/Auth'
import { useDispatch, useSelector } from 'react-redux'

const Dashboard = (props) => {
  const dispatch = useDispatch()

  const dashboardData = useSelector((state) => state.authReducer.dashboard)

  useEffect(() => {
    document.title = 'Albastini';
    window.scrollTo(0, 0)
    dispatch(dashboard())
    console.log(dashboardData,"this is my dashboard data");

  }, []); 



  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item> <FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
      </Breadcrumb>

      <div className="main-layout-des-page">

        <div className="dash-board-heading-text">
          <p>Total Registered User </p>
        </div>
        <div className="main-div-2 dashboard ">
          <div className="datacenter user-valid-text">
            <div className="image-fle">
              <div className="total-num">
                <h1>{dashboardData?.userDate?.total|| 0}</h1>
              </div>
              <div>
                <img alt="" src={images.down} style={{ width: '64px', Height: '150px' }} /></div>
            </div>
            <div className="user-text">
              <p>Total registered user count</p>
            </div>
          </div>

          <div className="datacenter user-valid-text">
            <div className="image-fle">
              <div className="total-num">
                <h1>{dashboardData?.userDate?.today || 0}</h1>
              </div>
              <div>
                <img alt="" src={images.download} style={{ width: '84px', Height: '150px' }} /></div>
            </div>
            <div className="user-text">
              <p> Total registered user count today </p>
            </div>
          </div>



          <div className="datacenter user-valid-text">
            <div className="image-fle">
              <div className="total-num">
                <h1>{dashboardData?.userDate?.week || 0}</h1>
              </div>
              <div>
                <img alt="" src={images.userWeek} style={{ width: '84px', Height: '150px' }} /></div>
            </div>
            <div className="user-text">
              <p>Total registered user counts this week</p>
            </div>

          </div>

          <div className="datacenter user-valid-text">
            <div className="image-fle">
              <div className="total-num">
                <h1>{dashboardData?.userDate?.month || 0}</h1>
              </div>
              <div>
                <img alt="" src={images.usermonth} style={{ width: '84px', Height: '150px' }} /></div>
            </div>
            <div className="user-text">
              <p>Total registered user counts this month</p>
            </div>

          </div>

          <div className="datacenter user-valid-text">
            <div className="image-fle">
              <div className="total-num">
                <h1>{dashboardData?.userDate?.quater || 0}</h1>
              </div>
              <div>
                <img alt="" src={images.userquarter} style={{ width: '84px', Height: '150px' }} /></div>
            </div>
            <div className="user-text">
              <p>Total registered user counts this quarter</p>
            </div>

          </div>

          <div className="datacenter user-valid-text">
            <div className="image-fle">
              <div className="total-num">
                <h1>{dashboardData?.userDate?.year || 0}</h1>
              </div>
              <div>
                <img alt="" src={images.thisyear} style={{ width: '84px', Height: '150px' }} /></div>
            </div>
            <div className="user-text">
              <p>Total registered user counts this year</p>
            </div>
          </div>

        </div>

        <div className="dash-board-heading-text">
          <p>Total Games Played </p>
        </div>
        <div className="main-div-2 dashboard ">


          {/* registered  */}

          <div className="datacenter user-valid-text">
            <div className="image-fle">
              <div className="total-num">
                <h1>{dashboardData?.matchData?.total || 0}</h1>
              </div>
              <div>
                <img alt="" src={images.totalGame} style={{ width: '71px', Height: '150px' }} /></div>
            </div>
            <div className="user-text">
              <p>Total games played</p>
            </div>
          </div>




          <div className="datacenter user-valid-text">
            <div className="image-fle">
              <div className="total-num">
                <h1>{dashboardData?.matchData?.today || 0}</h1>
              </div>
              <div>
                <img alt="" src={images.todayGame} style={{ width: '84px', Height: '150px' }} /></div>
            </div>
            <div className="user-text">
              <p> Total games played today </p>
            </div>
          </div>



          <div className="datacenter user-valid-text">
            <div className="image-fle">
              <div className="total-num">
                <h1>{dashboardData?.matchData?.week || 0}</h1>
              </div>
              <div>
                <img alt="" src={images.gameWeek} style={{ width: '84px', Height: '150px' }} /></div>
            </div>
            <div className="user-text">
              <p>Total games played this week</p>
            </div>

          </div>

          <div className="datacenter user-valid-text">
            <div className="image-fle">
              <div className="total-num">
                <h1>{dashboardData?.matchData?.month || 0}</h1>
              </div>
              <div>
                <img alt="" src={images.gamemonth} style={{ width: '84px', Height: '150px' }} /></div>
            </div>
            <div className="user-text">
              <p>Total games played this month</p>
            </div>

          </div>

          <div className="datacenter user-valid-text">
            <div className="image-fle">
              <div className="total-num">
                <h1>{dashboardData?.matchData?.quater || 0}</h1>
              </div>
              <div>
                <img alt="" src={images.gamequarter} style={{ width: '84px', Height: '150px' }} /></div>
            </div>
            <div className="user-text">
              <p>Total games played this quarter</p>
            </div>

          </div>

          <div className="datacenter user-valid-text">
            <div className="image-fle">
              <div className="total-num">
                <h1>{dashboardData?.matchData?.year || 0}</h1>
              </div>
              <div>
                <img alt="" src={images.gameyear} style={{ width: '84px', Height: '150px' }} /></div>
            </div>
            <div className="user-text">
              <p>Total games played this year</p>
            </div>
          </div>



        </div>

      </div>



    </div>



  )

}
export default Dashboard