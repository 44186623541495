import React, { useState, useEffect } from "react";
import { Pagination, Select, Spin } from "antd";
import images from "../../themes/appImage";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Table, Breadcrumb, Modal } from "antd";
import appconstant from "../../themes/appconstant";
import { useDispatch, useSelector } from "react-redux";
import { getGameListAction, shopListAction } from "../../redux/Action/Auth";
import { reduxConstant } from "../../constants/appconstant";
import { addItemSchema, removeEmojisForSearch } from "../../common/validation";
import { useFormik } from "formik";
import { ExclamationCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import moment from 'moment'

const ShopManagement = () => {
  const dispatch = useDispatch();
  const gameListData = useSelector(
    (state) => state.gameManagementReducer.gameList.list
  );
  const paginationData = useSelector(
    (state) => state.gameManagementReducer.gameList.paginationData
  );



  const loading = useSelector((state) => state.shopManagementReducer.isLoading);
  const [payload, setPayload] = useState({
    offset: 0,
    limit: 10,
    sort: null,
    order: null,
    search: "",
  });
  const [searchValue, setSearchValue] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [payloadImage, setPayloadImage] = useState(null);
  const [image, setImage] = useState(null);

  const handleChange = (col, page) => {
    const tempPayload = { ...payload,search:searchValue };

    if (col && col.columnKey) {
      tempPayload["sort"] = col?.columnKey || payload?.sort;
      tempPayload["order"] =
        col?.order === "ascend"
          ? 1
          : col?.order === "descend"
          ? -1
          : payload?.order;
    } else if (page) {
      tempPayload["offset"] = (page - 1) * 10;
    }
    setPayload(tempPayload);
    dispatch(getGameListAction({ ...tempPayload }));
  };
  useEffect(() => {
    dispatch(getGameListAction(payload));

    return () => Modal.destroyAll();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const formik = useFormik({
    initialValues: { itemName: "", cost: "", itemType: "", imageUrl: "" },
    enableReinitialize: true,
    validationSchema: addItemSchema,
    onSubmit: (values) => {
      let payload = new FormData();
      payload.append("itemName", values.itemName);
      payload.append("cost", values.cost);
      payload.append("imageUrl", payloadImage);
    },
  });

  const imageHandle = (e) => {
    if (e.target.files[0]) {
      setImage(URL.createObjectURL(e.target.files[0]));
      setPayloadImage(e.target.files[0]);
    }
  };

  useEffect(() => {
    if (searchValue?.length <= 50)
      dispatch(
        getGameListAction({ ...payload, search: searchValue, offset: 0 })
      );
  }, [searchValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearch = (e) => {
    const serachData = removeEmojisForSearch(e?.target?.value);
    if (!serachData?.startsWith(" ")) setSearchValue(serachData);
  };

  const hideModal = () => {
    formik.resetForm();
    setModalVisible(false);
  };

  const dataSource = gameListData?.map((e) => {
    return {
      _id: e?._id,
      game_id: e?._id,
      first_player: e?.playerOne,
      second_player: e?.playerTwo,
      start_date_time: e?.startTime,
      endTime: e?.endTime,
      game_amount_coins: e.coins,
      winner_name: e?.winner || "",
      game_type: e?.type,
    };
  });

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "srno",
      key: "srno",
      sorter: false,
      align: 'left',
      render: (value, item, index) =>((paginationData?.currentPage - 1)*10) + (index + 1),
    },

    {
      title: "Game ID", 
      dataIndex: "gameid",
      key: "gameid ",
      sorter: false,
      render: (title, data, index) => <>{data?.game_id || "N/A"}</>,
    },

    {
      title: "First player",
      dataIndex: "firstplayer",
      key: "firstplayer",
      sorter: false,
      render: (title, data, index) => <>{data?.first_player || "N/A"}</>,
    },
    {
      title: "Second player",
      dataIndex: "secondplayer",
      key: "secondplayer",
      sorter: false,
      render: (title, data, index) => <>{data?.second_player || "N/A"}</>,
    },

    {
      title: "Start Date & Time",
      dataIndex: "startdatetime",
      key: "startdatetime",
      sorter: false,
      render: (title, data, index) => <>{moment(data?.start_date_time).format("DD/MM/YYYY HH:mm") || "N/A"}</>,
    },
    {
      title: "End Date & Time.",
      dataIndex: "endTime.",
      key: "endTime.",
      render: (title, data, index) => <>{moment(data?.endTime).format("DD/MM/YYYY HH:mm") || "N/A"}</>,
    },
    {
      title: "Game amount (Coins)",
      dataIndex: "gameamount",
      key: "gameamount.",
      render: (title, data, index) => <>{data?.game_amount_coins }</>,
    },
    {
      title: "Winner name",
      dataIndex: "winnername",
      key: "winnername",
      render: (title, data, index) => <>{data?.winner_name || "N/A"}</>,
    },
    {
      title: "Game type",
      dataIndex: "gametype",
      key: "gametype",
      render: (title, data, index) => <>{data?.game_type || "N/A"}</>,
    },
    {
      title: 'Actions',
      dataIndex: 'Posted',
      key: 'Posted',
      render: (value, item, index) => {
          return (

              <div>
                  

                
                    
            <Link to="/gamelog" state={item?._id}>
            <button className="tour-btn" type="submit">
              Game Logs
            </button>
          </Link>

              </div>
          )
      }
  },
  ];
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.token;

    token && navigate("/gamemanagement");
    document.title = "Albastini";
  }, []);

  return (
    <div>
      {/* <BreadcrumbFn
                path={["dashboard", "usermanagement"]}
                pathName={[
                    <FontAwesomeIcon icon={faHome} />,
                    appconstant.UserManagemen,
                    
                ]}

            /> */}

      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard">
            <FontAwesomeIcon icon={faHome} />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{appconstant.GameManagement}</Breadcrumb.Item>
      </Breadcrumb>

      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div className="content-e">
          <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
            <h6 className="text-white text-capitalize ps-3">
              {appconstant.GameManagement}
            </h6>
            <input
              type="text"
              onChange={handleSearch}
              className="iocn-search  search-bar"
              placeholder="Search"
              value={searchValue}
            />
          </div>

          <Table
            className="unser-manage-table"
            dataSource={dataSource?.map((item, index) => ({
              ...item,
              key: index,
            }))}
            columns={columns}
            showSorterTooltip={false}
            loading={loading}
            pagination={false}
            onChange={(x, y, col) => handleChange(col)}
          />
          <Pagination
            showTitle={false}
            className="pagination-wrapper"
            showSizeChanger={false}
            total={paginationData?.totalCount}
            current={paginationData?.currentPage}
            onChange={(page) => handleChange(null, page)}
          />
          <Modal
            title={
              <div className="modal-header">
                <ExclamationCircleOutlined className="modal-icon" />
                {appconstant.addShopItem}
              </div>
            }
            centered={true}
            maskClosable={true}
            onCancel={hideModal}
            className="create-admin-modal"
            closable={false}
            visible={modalVisible}
            onSubmit={hideModal}
            footer={false}
          >
            <div className="create-item-form-group">
              <div className="form-header">
                <div className="main-div">
                  <form
                    className="create-item-form-wrapper"
                    onSubmit={formik.handleSubmit}
                  >
                    <div className="wrapper_line">
                      <div className="form-group for-bottom-margin">
                        <label>{appconstant.itemName}</label>
                        <div className="form-input-wrapper">
                          <div className="border-input">
                            <input
                              className="input-wrapper"
                              name="itemName"
                              type="text"
                              placeholder={appconstant.iteamName}
                              value={formik.values.itemName}
                              onChange={(e) => {
                                if (
                                  e.target.value.startsWith(" ") ||
                                  !/^[A-Za-z0-9 ]*$/.test(e.target.value)
                                )
                                  e.preventDefault();
                                else formik.handleChange(e);
                              }}
                            />
                          </div>
                          <div className="add-item-validation-error">
                            {formik.errors.itemName ?? null}
                          </div>
                        </div>
                      </div>

                      <div className="form-group for-bottom-margin">
                        <label>{appconstant.cost}</label>
                        <div className="form-input-wrapper">
                          <div className="border-input">
                            <input
                              className="input-wrapper"
                              name="cost"
                              type="text"
                              placeholder={appconstant.cost}
                              value={formik.values.cost}
                              onChange={(e) => {
                                if (
                                  e.target.value.startsWith(" ") ||
                                  !/^[0-9]*$/.test(e.target.value)
                                )
                                  e.preventDefault();
                                else formik.handleChange(e);
                              }}
                            />
                          </div>
                          <div className="add-item-validation-error">
                            {formik.errors.cost ?? null}
                          </div>
                        </div>
                      </div>

                      <div className="form-group for-bottom-margin">
                        <label>{appconstant.type}</label>
                        <div className="form-input-wrapper">
                          <div className="border-input">
                            <Select
                              id={"id"}
                              showArrow
                              // value={"nnn"}
                              className="input-wrapper"
                              style={{ width: "100%" }}
                              name="itemType"
                              onChange={(e) => {
                                formik.setFieldValue("itemType", e);
                              }}
                              showSearch={false}
                              placeholder="Select Item Type"
                              options={[
                                { value: "option1", label: "Option 1" },
                                { value: "option2", label: "Option 2" },
                                { value: "option3", label: "Option 3" },
                                { value: "option4", label: "Option 4" },
                                { value: "option5", label: "Option 5" },
                                { value: "option6", label: "Option 6" },
                              ]}
                            />
                          </div>
                          <div className="add-item-validation-error">
                            {formik.errors.itemType ?? null}
                          </div>
                        </div>
                      </div>

                      <div className="form-group for-bottom-margin for-top datacenter">
                        <label
                          htmlFor="file-input"
                          className="user-detail-image-label"
                          style={{ borderRadius: "100%", overflow: "hidden" }}
                        >
                          {!image && (
                            <div className="datacenter-icon">
                              <img
                                alt=""
                                src={images.pluss}
                                title="Click to edit image"
                              />
                            </div>
                          )}
                          <img
                            alt=""
                            src={image ? image : images.usermessage}
                            name="imageUrl"
                            style={{
                              width: "100px",
                              Height: "200px",
                              borderRadius: "100%",
                              overflow: "hidden",
                            }}
                          />
                        </label>
                        <input
                          id="file-input"
                          type="file"
                          onChange={imageHandle}
                          className="hidden"
                          accept="image/png, image/jpg, image/jpeg"
                        />
                        <div className="add-item-validation-error">
                          {formik.errors.imageUrl ?? null}
                        </div>
                      </div>

                      <div className="up-cent">
                        <button
                          className="add-item-button-form"
                          disabled={loading}
                          onClick={formik.handleSubmit}
                          type="submit"
                        >
                          {loading ? (
                            <Spin indicator={<LoadingOutlined spin />} />
                          ) : (
                            appconstant.addShopItem
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};
export default ShopManagement;
